
import { defineComponent } from '@nuxtjs/composition-api'
import { getIndirectClientHeaders, seoHead } from '~/assets/seo'
import { getSiteConfig } from '~/assets/craft'

export default defineComponent({
  name: 'LandingDetail',
  nuxtI18n: {
    paths: {
      nl: '/www/:departmentTypeSlug(dierenambulance|dierenasiel|hondenschool|wildopvang)/:siteSlug',
    },
  },
  async asyncData({ error, redirect, req, res, route, store }) {
    const { site, siteSlug } = getSiteConfig(route)
    // When the siteSlug is `undefined` this means that the `:siteSlug` in the url did not match
    // any of the known sub-sites.  Therefore, we must follow the seo redirection rules immediately.
    if (siteSlug === undefined) {
      const seoRedirect = await store.dispatch('seo/fetch', { site, path: route.fullPath })
      if (seoRedirect) {
        res.setHeader('Cache-Control', 'max-age=600, must-revalidate, stale-while-revalidate=60')
        return redirect(seoRedirect.type, seoRedirect.to)
      }
      return error({ statusCode: 404, message: 'Page not found' })
    }

    const [, , seoGlobal, page] = await Promise.all([
      store.dispatch('navigation/fetch', { site }),
      store.dispatch('global/fetch', { site, push: true }),
      store.dispatch('global/fetchSeo'),
      store.dispatch('page/fetchUri', { site, uri: `__home__`, push: true, options: { headers: getIndirectClientHeaders({ req }) } }),
    ])

    if (!page) {
      const seoRedirect = await store.dispatch('seo/fetch', { site, path: route.fullPath })
      if (seoRedirect) {
        res.setHeader('Cache-Control', 'max-age=600, must-revalidate, stale-while-revalidate=60')
        return redirect(seoRedirect.type, seoRedirect.to)
      }
      return error({ statusCode: 404, message: 'Page not found' })
    }

    return {
      page,
      seoGlobal,
    }
  },
  head() {
    return seoHead(this.page, this.seoGlobal)
  },
  httpHeaders: () => ({
    'Cache-Control': 's-maxage=21600, stale-while-revalidate=86400, stale-if-error=86400',
  }),
})
